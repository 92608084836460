@import '~ui-components-rollup/src/components/constants';

.base {
  background-color: $grey-background;
  padding-top: $app-header-height;
  min-height: 100vh;
  padding-bottom: 35px;
  transition: padding-left 0.4s cubic-bezier(.25, .8, .25, 1);
  min-width: 1260px;
}

.with-nav-menu {
  padding-left: $newNavMenuWidth;

  :global {
    .nav-adjusted-fixed-pane {
      width: calc(100% - #{$newNavMenuWidth}) !important;
    }
  }
}