
.maintenance-table{
    max-width: 95%;
    border-radius: 5px 5px 0 0;
    margin: 50px auto auto;
    font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen-Sans,Ubuntu,Cantarell,Helvetica Neue,sans-serif;
}

.maintenance-table-header{
    background: rgb(61, 132, 255) calc(50% - 1px);
    color: white;
    height: 45px;
    font-size: 1.5em;
    border-radius: 4px 4px 0 0;
    overflow: visible;
}

.maintenance-sub-table{
    padding:15px 0 15px 15px;
    maxWidth:90%;
    margin: 0 0 35px 35px;
}