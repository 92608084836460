.rt-table{
  overflow: visible !important;
  .rt-tr-group{
    max-height: 37px;
  }
  .max-height-none{
    max-height: none
  }
  .-filters{
    height: 50px;
  }
}
.Select-menu{
  background-color: red;
}