@import '~ui-components-rollup/src/components/constants';

.name {
  float: right;
  color: #fff;
  margin-right: 15px;
  font-size: 14px;
  line-height: $app-header-height;
}

.logout {
  float: right;
  color: #fff;
  line-height: $app-header-height;
}

.base {
  padding-right: $padding-large;
}

.widgets{
  float: right;
  width: 600px;
  text-align: right;
  margin-right: 45px;
  button{
    margin-top: 3px;
    float: right;
    line-height: 30px;
  }
}

.refreshButton{
  background-color: transparent;
  margin-right: 30px;
  svg {
    width:25px;
    height: 25px;
  }
}

.refresh-inline-loader{
  float: right;
  margin-top: 7px;
  margin-right: 37px;
}

.organizationSelect{
  display: inline-block;
  margin-right: 10px;
  cursor: pointer;
}