.table-header{
  text-align: center;
  height: 70px;
  background-color: #fbfbfb;
  box-shadow: 2px 2px 6px 0 rgba(187, 187, 187, 0.5);
  transition: box-shadow 0.3s ease-in-out;
  h1{
    font-size: 40px;
    color: #034c90;
    line-height: 70px;
  }
}