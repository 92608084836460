$padding-large: 80px;
$padding-medium: 40px;

$break-medium: 1690px;
$break-small: 1366px;

$header-height: 35px;
$bottom-padding: 35px;

$menuZ: 5000;
$headerZ: 5001;
$newMenuZ: $headerZ + 10;

$navMenuWidth: 325px;
$newNavMenuWidth: 150px;

$app-header-height: 35px;
$secondary-header-height: 107px;
$new-secondary-header-height: 130px;
$new-secondary-header-height-medium: 125px;
$new-secondary-header-height-small: 120px;

$primary-blue: #3d84ff;
$secondary-blue: #003eae;
$lochmara-blue: #2c5e9c;
$mariner-blue: #2B67C5;
$link-water-blue: #e8f2f8;
$light-bluish-white: #f6fbff;
$deep-blue: #01225e;

$white-background: #fbfbfb;
$grey-background: #f5f5f5;
$pinkish-grey: #f4f5fc;
$light-grey: #d7d7d7;
$greyish: #adadad;
$grey-5: #6c6f78;
$grey-6: #434957;
$grey-7: #e4e4e4;
$grey-8: #7b7b7b;
$text-dark-grey: #636363;

$text-error: #ca3652;
$error: #ca3658;
$warning: #ffa100;
$info: #3c84ff;
$success: #30a998;
$turquoise-blue: #00afc5;
$primary-blue-hover: #326cd1;
$cancel-button-hover-grey: #c8c8c8;
$danger-button-hover-red: #ac2d45;

$category-storage: #5933b4;
$category-services: #1ca6ff;
$category-controls: #cc3cd6;
$category-analytics: #3CB09F;

$category-storage-secondary: #e2ddef;
$category-services-secondary: #d3e7f4;
$category-storage-controls: #fde6ff;
$category-services-analytics: #d9f2ee;

$two-colors-icon-primary-color: $grey-6;
$two-colors-icon-secondary-color: $primary-blue;

@mixin new-standard-view($padding: 60px) {
  padding-top: $new-secondary-header-height + $padding;
  position: relative;

  @media(max-width: $break-small) {
    padding-top: $new-secondary-header-height-small + $padding;
  }
}

%standard-card {
  background-color: $white-background;
  box-shadow: 2px 2px 6px 0 rgba(187, 187, 187, 0.5);
  transition: box-shadow 0.3s ease-in-out;

  &:hover:not([disabled]) {
    box-shadow: 4px 4px 15px 0 rgba(187, 187, 187, 0.7);
  }
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

.inline-loader {
  height: 20px;
  width: 20px;
  margin-right: 30px;
  margin-left: auto;
  animation: spin .8s linear infinite;
}

@keyframes eightshimmer {
  0% {
    background-position: -678px 0
  }
  100% {
    background-position: 678px 0
  }
}

@mixin loading-mask($width: 110px) {
  width: $width;
  animation-duration: 3.818181818181818s * (110px / $width);
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: eightshimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #f6f7f8 8%, #EDEDED 18%, #f6f7f8 33%);
  background-size: 200% 102%;
}
